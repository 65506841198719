import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BookingContext } from "pages/club-fitting/booking";
import { Dayjs } from "dayjs";
import { IService } from "Interfaces/Service";
import { ITimeSlot } from "Interfaces/TimeSlot";
import Loading from "components/Loading";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ServiceServices from "services/Service";
import TimeSlotServices from "services/TimeSlot";
import { formatPhoneNumber } from "util/common";
import liff from "@line/liff/dist/lib";

const BookingInfo = () => {
  const {
    onChangeStep,
    input,
    handleInput,
    onSetTimeSlots,
    timeSlots,
    services,
    onSetServices,
  } = useContext(BookingContext);
  const [maxDurationServices, setMaxDurationServices] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [isSlotTimeReady, setIsSlotTimeReady] = useState<boolean>(false);
  const [isServicesReady, setIsServicesReady] = useState<boolean>(false);

  useEffect(() => {
    ServiceServices.getAll().then((res) => {
      let servicesData: IService[] = res.data.data;
      let max = 0;
      servicesData.forEach((s) => {
        max += s.duration;
      });
      setMaxDurationServices(max > 60 ? 60 : max);
      onSetServices(servicesData);
      setIsServicesReady(true);
    });
  }, []);

  useEffect(() => {
    getTimeSlot();
  }, [input.booking_date]);

  const getTimeSlot = async () => {
    let dataProps = {
      date: input.booking_date,
      line_id: "",
    };
    await liff
      .init({ liffId: `${process.env.REACT_APP_LIFF_CF}` })
      .catch((err) => {
        console.log(err);
      });
    if (liff.isLoggedIn()) {
      let lineProfile = await liff.getProfile();
      dataProps.line_id = lineProfile.userId;
    }
    if (dataProps.date && dataProps.line_id) {
      TimeSlotServices.getAll(dataProps)
        .then((res) => {
          let slotTimes: ITimeSlot[] = res.data.data;
          onSetTimeSlots(slotTimes);
          setError("");
          setIsSlotTimeReady(true);
        })
        .catch((err) => {
          switch (err.response.data.message) {
            case "today is limit booking for you":
              setError("Today is limit for you.");
              break;
            case "today is full":
              setError("Today is full.");
              break;
            default:
              setError("Something went wrong. Please try again later.");
              break;
          }
          setIsSlotTimeReady(true);
        });
    }
  };

  const onCheckService = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (e.target.checked) {
      let newServices = input.services;
      newServices.push(id);
      handleInput("services", newServices);
    } else {
      let newServices = input.services.filter((s) => s !== id);
      handleInput("services", newServices);
    }
  };

  const startSlotMin = useMemo(() => {
    let slotMin = 0;
    if (input.start_slot_time_id) {
      timeSlots.forEach((ts) => {
        if (ts.id === input.start_slot_time_id) {
          slotMin = ts.time_slot;
        }
      });
    }
    return slotMin > maxDurationServices ? maxDurationServices : slotMin;
  }, [input, timeSlots, maxDurationServices]);

  const allDurationServices = useMemo(() => {
    let min = 0;
    input.services.forEach((s) => {
      services.forEach((sv) => {
        if (s === sv.id) {
          min += sv.duration;
        }
      });
    });

    return min;
  }, [input, services]);

  const isOver = useMemo(() => {
    return allDurationServices > startSlotMin;
  }, [allDurationServices, startSlotMin]);

  const handleCancelInfo = () => {
    liff.closeWindow();
  };

  return (
    <div>
      <p className="mb-[26px]">Golf Club Demo</p>
      <Box
        sx={{
          "& > :not(style)": { width: "100%" },
        }}
      >
        {isServicesReady && isSlotTimeReady ? (
          <>
            <TextField
              required
              id="first_name"
              label="First name"
              type="text"
              name="name"
              onChange={(e) => handleInput("first_name", e.target.value)}
              value={input.first_name}
            />
            <Box sx={{ height: 20 }} />
            <TextField
              required
              id="last_name"
              label="Last name"
              type="text"
              name="lastname"
              onChange={(e) => handleInput("last_name", e.target.value)}
              value={input.last_name}
            />
            <Box sx={{ height: 20 }} />
            <TextField
              required
              id="telno"
              label="Phone number"
              type="tel"
              name="telno"
              inputProps={{
                maxLength: 12,
              }}
              onChange={(e) => {
                handleInput("tel", e.target.value.replaceAll("-", ""));
              }}
              value={formatPhoneNumber(input.tel)}
            />
            <Box sx={{ height: 20 }} />
            <TextField
              id="email"
              label="Email"
              type="email"
              name="email"
              onChange={(e) => handleInput("email", e.target.value)}
              value={input.email}
            />
            <Box sx={{ height: 20 }} />
            <div className="mb-[20px]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label={"Booking date *"}
                  inputFormat="YYYY-MM-DD"
                  value={input.booking_date}
                  minDate={new Date() as any}
                  onChange={(newValue: Dayjs | null) =>
                    handleInput("booking_date", newValue?.format("YYYY-MM-DD"))
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      value={input.booking_date}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            {error ? (
              <p className=" text-center p-5 border border-[#EF2F2C] rounded-md">{error}</p>
            ) : (
              <>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Start time *
                  </InputLabel>
                  <Select
                    labelId="start_slot_time_id"
                    id="start_slot_time_id"
                    name="start_slot_time_id"
                    value={input.start_slot_time_id}
                    label="Start time *"
                    onChange={(e) =>
                      handleInput("start_slot_time_id", e.target.value)
                    }
                  >
                    {timeSlots.map((ts) => {
                      return (
                        <MenuItem value={ts.id}>{`${ts.time_start.substring(
                          0,
                          5
                        )} น.`}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div>
                  <p className="body-b mt-[20px]">
                    Services *{" "}
                    {startSlotMin ? `(Available ${startSlotMin} mins)` : ""}
                  </p>
                  {input.start_slot_time_id && isOver ? (
                    <p className="text-red">
                      the duration of services booking exceeds the booking time
                      slot.
                    </p>
                  ) : null}
                  {services.map((s) => {
                    return (
                      <div>
                        <Checkbox
                          id={`services-${s.id}`}
                          key={s.id}
                          onChange={(e) => onCheckService(e, s.id)}
                          name="services"
                          checked={
                            input.services.filter((sv) => sv === s.id)
                              .length === 1
                          }
                        />
                        <label
                          htmlFor={`services-${s.id}`}
                        >{`${s.name} ( ${s.duration} mins )`}</label>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <div className="py-[35px] bg-white rounded-lg">
              <div className="container grid grid-cols-2">
                <button
                  type="button"
                  className="text-[#EF2F2C] body-b"
                  onClick={handleCancelInfo}
                >
                  Cancel
                </button>
                <button
                  className="text-white bg-[#EF2F2C] disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
                  disabled={
                    !(
                      !isOver &&
                      input.first_name &&
                      input.last_name &&
                      input.tel.length === 10 &&
                      input.booking_date &&
                      input.start_slot_time_id &&
                      input.services.length > 0
                    )
                  }
                  onClick={() => {
                    onChangeStep(2);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </>
        ) : (
          <Loading color="error" />
        )}
      </Box>
    </div>
  );
};

export default BookingInfo;
